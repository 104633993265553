import axiosOG, { Axios } from 'axios'
import qs from 'qs'
import { getSession } from 'next-auth/react'
import { API_URL } from '../config'

/**
 * Converts a JSON to a string of query params.
 * @param {Record<string, unknown>} params
 * @return {String}
 */
function serializeParams(params: Record<string, unknown>): string {
  return qs.stringify(params, { arrayFormat: 'repeat' })
}

/**
 * Build an Axios instance.
 * @return {Axios}
 */
function getAxios(): Axios {
  const instance = axiosOG.create({
    baseURL: API_URL,
  })

  instance.interceptors.request.use(async (request) => {
    const session = await getSession()

    request.paramsSerializer = serializeParams
    const payload = JSON.stringify(request.data)

    console.log(
      '[REQUEST]',
      request.url,
      `method=${request.method?.toUpperCase() || 'Not defined'}`,
      `baseURL=${request.baseURL}`,
      request.data ? `payload=${payload}` : '',
      request.params ? `params=${serializeParams(request.params)}` : ''
    )

    if (session?.accessToken && request.headers) {
      request.headers['Authorization'] = `Bearer ${session.accessToken}`
    }

    return request
  })

  instance.interceptors.response.use(
    (response) => {
      return response.data
    },
    (error) => {
      const { response } = error
      const responseData = JSON.stringify(error.response?.data, null)
      console.error(
        '[REQUEST ERROR]',
        response.config.url,
        `status=${response.status}`,
        `method=${response.config.method?.toUpperCase()}`,
        `baseURL=${response.config?.baseURL}`,
        `payload=${response.config?.data}`,
        `response=${responseData}`
      )

      throw error
    }
  )

  return instance
}

const axios = getAxios()

export default axios
