import create from 'zustand'
import { User } from '../ts/types/user.types'
import { loadUserSafely } from '../utils/auth'
import { Commune } from '../ts/types/commune.types'
import { loadUserCommunesSafely } from '../utils/communes'
import { initializeLogRocket } from '../utils/logrocket'

interface AuthState {
  user: User | null
  communes: Commune[]
  ready: boolean
  loadFromStorage: () => Promise<void>
  refreshUser: () => Promise<void>
}

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  ready: false,
  communes: [],
  loadFromStorage: async () => {
    const result = await Promise.all([loadUserSafely(), loadUserCommunesSafely()])

    if (result[0]) {
      initializeLogRocket(result[0])
    }

    set({
      user: result[0],
      communes: result[1],
      ready: true,
    })
  },
  refreshUser: async () => {
    const result = await Promise.all([loadUserSafely(), loadUserCommunesSafely()])
    set({ user: result[0], communes: result[1] })
  },
}))
