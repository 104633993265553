import React, { ReactNode } from 'react'
import CustomText from '../base/CustomText'

type Props = {
  title: string
  description?: string
  children?: ReactNode
  icon?: React.ReactElement
}

const Placeholder = ({ title, description, icon, children }: Props): JSX.Element => {
  return (
    <div className="flex flex-col items-center justify-center space-y-4">
      {icon ? React.cloneElement(icon, { className: 'text-5xl text-gray-dark' }) : null}

      <div className="text-center">
        <CustomText size="heading-4" weight={600} className="text-gray-darker">
          {title}
        </CustomText>
        {description ? (
          <CustomText size="caption" weight={400} className="text-gray-darker">
            {description}
          </CustomText>
        ) : null}
      </div>

      {children ? <div>{children}</div> : null}
    </div>
  )
}

export default Placeholder
