// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://9d11b910e8d94ea5a909bc92a99a741e@o1229068.ingest.sentry.io/6375051',
  // Adjust this value in production, or use tracesSampler for greater control
  enabled: process.env.NODE_ENV !== 'development',
  tracesSampleRate: process.env.APP_ENV === 'production' ? 0.5 : 1.0,
  environment: process.env.APP_ENV,
  release: `elecnor-panel@${process.env.NEXT_PUBLIC_VERSION_NUMBER}`,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
