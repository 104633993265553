import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import { Loader } from 'rsuite'
import Link from 'next/link'
import { VERSION_NUMBER } from '../../config'
import CustomText from '../base/CustomText'

const FullScreenLoader = (): JSX.Element => {
  const [isTakingTooLong, setIsTakingTooLong] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsTakingTooLong(true)
    }, 5000)
  }, [])

  return (
    <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center  space-y-6">
      <Image src="/static/images/logo.svg" alt="Elecnor logo" width={600 * 0.3} height={225 * 0.3} />
      <Loader />

      {isTakingTooLong ? (
        <CustomText size="caption" weight={400} className="text-center text-gray-darker max-w-xs">
          Está tardando mucho tiempo. Comprueba tu conexión a internet o contacta a soporte.
        </CustomText>
      ) : null}

      <div className="absolute bottom-0 left-0 w-full p-3">
        <Link href="/changelog">
          <a target="_blank">
            <CustomText size="tiny" weight={400} className="text-center text-gray-dark hover:underline">
              SIMAP V{VERSION_NUMBER}
            </CustomText>
          </a>
        </Link>
      </div>
    </div>
  )
}

export default FullScreenLoader
