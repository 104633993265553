import { useEffect } from 'react'
import { useAuthStore } from '../dataStores/auth'

const useManagerAuth = () => {
  const loadFromStorage = useAuthStore((state) => state.loadFromStorage)

  useEffect(() => {
    loadFromStorage()
  }, [loadFromStorage])
}

export default useManagerAuth
