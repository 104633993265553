import { useRouter } from 'next/router'
import shallow from 'zustand/shallow'
import { useAuthStore } from '../dataStores/auth'

type ReturnProps = {
  belongsTo: (communeSlug: string | string[]) => boolean
  hasPermission: (permission: string) => boolean
}

const usePermissions = (): ReturnProps => {
  const { query } = useRouter()
  const [user, communes] = useAuthStore((state) => [state.user, state.communes], shallow)

  const belongsTo = (communeSlug: string | string[]) => {
    const slug = Array.isArray(communeSlug) ? communeSlug[0] : communeSlug
    return !!communes?.find((commune) => commune.slug === slug)
  }

  const hasPermission = (permission: string) => {
    if (user?.is_superuser) {
      return true
    }

    const userPermissions =
      user?.memberships
        .filter((membership) => membership.commune.slug === query.communeSlug)
        .reduce<string[]>((acc, current) => [...acc, ...current.role.permissions], []) || []

    return userPermissions.includes(permission)
  }

  return {
    belongsTo,
    hasPermission,
  }
}

export default usePermissions
