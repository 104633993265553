import { useSession } from 'next-auth/react'
import React, { ReactNode, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useAuthStore } from '../../dataStores/auth'
import usePermissions from '../../hooks/usePermissions'
import FullScreenLoader from './FullScreenLoader'
import FullScreenUnmetPermission from './FullScreenUnmetPermission'

type Props = {
  children?: ReactNode
  requiredPermission?: string
}

const AuthGateway = ({ children, requiredPermission }: Props): JSX.Element => {
  const { query, replace } = useRouter()
  const ready = useAuthStore((state) => state.ready)
  const { data: session } = useSession({ required: true })
  const { belongsTo, hasPermission } = usePermissions()

  const isAuthReady = !!session?.user && ready
  const isCommuneForbidden = query?.communeSlug ? !belongsTo(query.communeSlug) : false
  const meetPagePermission = requiredPermission ? hasPermission(requiredPermission) : true

  useEffect(() => {
    if (isAuthReady && isCommuneForbidden) {
      replace('/communes')
    }
  }, [isCommuneForbidden, isAuthReady, replace])

  if (isAuthReady && !isCommuneForbidden && !meetPagePermission) {
    return <FullScreenUnmetPermission />
  }

  if (isAuthReady && !isCommuneForbidden) {
    return <>{children}</>
  }

  return <FullScreenLoader />
}

export default AuthGateway
