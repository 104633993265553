import React from 'react'
import { Button } from 'rsuite'
import CharacterLockIcon from '@rsuite/icons/CharacterLock'
import Link from 'next/link'
import CustomText from '../base/CustomText'
import { VERSION_NUMBER } from '../../config'
import CustomLink from './CustomLink'
import Placeholder from './Placeholder'

const FullScreenUnmetPermission = (): JSX.Element => {
  return (
    <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center space-y-6 p-4">
      <Placeholder
        title="No posees permisos para acceder a esta página"
        description="Si crees que se trata de un error, contacta al administrador del sistema"
        icon={<CharacterLockIcon />}
      >
        <Button as={CustomLink} href="/" appearance="primary">
          Volver al inicio
        </Button>
      </Placeholder>

      <div className="absolute bottom-0 left-0 w-full p-3">
        <Link href="/changelog">
          <a target="_blank">
            <CustomText size="tiny" weight={400} className="text-center text-gray-dark hover:underline">
              SIMAP V{VERSION_NUMBER}
            </CustomText>
          </a>
        </Link>
      </div>
    </div>
  )
}

export default FullScreenUnmetPermission
