import clsx from 'clsx'
import { ReactNode } from 'react'

type Props = {
  size:
    | 'heading-1'
    | 'heading-2'
    | 'heading-3'
    | 'heading-4'
    | 'subtitle-1'
    | 'subtitle-2'
    | 'paragraph'
    | 'caption'
    | 'tiny'
  weight: 900 | 800 | 700 | 600 | 400 | 300
  children?: ReactNode | string
  className?: string
  style?: object
  /**
   * If true, shows a large text at the end of children
   */
  debug?: boolean
}

const CustomText = ({ size = 'paragraph', weight = 400, children, className, style, debug }: Props): JSX.Element => {
  const Component = availableTags[size] || 'p'
  const sizeClassName = availableSizeClassNames[size] || 'text-base'
  const weightClassname = availableWeightClassNames[weight] || 'font-normal'

  return (
    <Component className={clsx(sizeClassName, weightClassname, className)} style={style}>
      {children}{' '}
      {debug
        ? 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab assumenda culpa cumque cupiditate earum eius ex odio perferendis, praesentium quas repudiandae sapiente sint sit, tempora ullam, unde veniam vitae voluptate!'
        : null}
    </Component>
  )
}

const availableTags: Record<string, keyof JSX.IntrinsicElements> = {
  'heading-1': 'h1',
  'heading-2': 'h2',
  'heading-3': 'h3',
  'heading-4': 'h4',
  'subtitle-1': 'p',
  'subtitle-2': 'p',
  paragraph: 'p',
  caption: 'p',
  tiny: 'small',
}

const availableSizeClassNames = {
  'heading-1': 'text-4xl sm:text-5xl',
  'heading-2': 'text-3xl sm:text-4xl',
  'heading-3': 'text-2xl sm:text-3xl',
  'heading-4': 'text-xl sm:text-2xl',
  'subtitle-1': 'text-lg sm:text-xl',
  'subtitle-2': 'text-base sm:text-lg',
  paragraph: 'text-sm sm:text-base block',
  caption: 'text-xs sm:text-sm block',
  tiny: 'text-xs block',
}

const availableWeightClassNames = {
  900: 'font-black',
  800: 'font-extrabold',
  700: 'font-bold',
  600: 'font-semibold',
  400: 'font-normal',
  300: 'font-light',
}

export default CustomText
