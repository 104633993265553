import jwtDecode from 'jwt-decode'
import { API_URL } from '../config'
import { User } from '../ts/types/user.types'
import { findMyUser } from '../services/authenticationService'

interface RefreshAccessToken {
  accessToken?: string
  accessTokenExp?: number
  error?: string
}

export const refreshAccessToken = async (refreshToken: string): Promise<RefreshAccessToken> => {
  try {
    const response = await fetch(`${API_URL}/users/token/refresh/`, {
      method: 'POST',
      body: JSON.stringify({ refresh: refreshToken }),
      headers: { 'Content-Type': 'application/json' },
    })

    const tokens: { access: string } = await response.json()

    if (!response.ok) {
      return {
        error: 'RefreshAccessTokenError',
      }
    }

    const accessTokenData: { exp: number } = jwtDecode(tokens.access)

    return {
      accessToken: tokens.access,
      accessTokenExp: accessTokenData.exp,
    }
  } catch (e) {
    console.log(e)
    return {
      error: 'RefreshAccessTokenError',
    }
  }
}

export const loadUserSafely = async (): Promise<User | null> => {
  try {
    return await findMyUser()
  } catch (e) {
    return null
  }
}
