import { ReactNode } from 'react'
import Link from 'next/link'

type Props = {
  children?: ReactNode
  href?: string
  className?: string
  style?: object
  onClick?: () => void
}

// Component that merges Link and in the same component in order to be used in a RSuite button or nav button.
const CustomLink = ({ children, href, className, style, onClick }: Props): JSX.Element => {
  return (
    <Link href={href || '/'}>
      <a className={className} style={style} onClick={onClick}>
        {children}
      </a>
    </Link>
  )
}

export default CustomLink
