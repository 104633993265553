const environment = process.env.APP_ENV

console.log('Environment:', environment)

export const API_URL = process.env.NEXT_PUBLIC_API_URL

export const SITE_NAME = 'Elecnor'

export const GOOGLE_ANALYTICS_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID

export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL

export const VERSION_NUMBER = process.env.NEXT_PUBLIC_VERSION_NUMBER

export const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string
