import { setLocale } from 'yup'
import * as yup from 'yup'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

export const checkRut = (rut?: string) => {
  if (!rut) {
    return false
  }

  const value = rut.replace(/\./g, '').replace('-', '')

  if (value.length < 7) {
    return false
  }

  const body = value.slice(0, -1)
  let digit = value.slice(-1).toUpperCase()

  let sum = 0
  let multiple = 2

  for (let i = 1; i <= body.length; i++) {
    const index = multiple * parseInt(value.charAt(body.length - i))

    sum = sum + index

    if (multiple < 7) {
      multiple = multiple + 1
    } else {
      multiple = 2
    }
  }

  const expectedDigit = 11 - (sum % 11)

  digit = digit === 'K' ? '10' : digit
  digit = digit === '0' ? '11' : digit

  return expectedDigit.toString() === digit
}

export const formatRut = (rut: string) => {
  const regex = /^([0-9]{1,2}?)([0-9]{2,3})([0-9]{2,3})([0-9kK]{1})$/gm
  const subst = `$1.$2.$3-$4`
  return rut
    .toUpperCase()
    .replace(/[\\.|-]/g, '')
    .replace(regex, subst)
}

export const setDefaultYupMessages = () => {
  setLocale({
    mixed: {
      required: 'Este campo es requerido.',
    },
    number: {
      integer: 'Debe ser un número entero.',
      positive: 'Debe ser un número mayor a 0.',
    },
    string: {
      email: 'El email no es válido.',
      url: 'La URL no es válida.',
    },
  })
}

export const initYup = () => {
  setDefaultYupMessages()

  yup.addMethod<yup.StringSchema>(yup.string, 'rut', function () {
    return this.test('rut', 'El rut no es válido.', checkRut)
  })

  yup.addMethod<yup.StringSchema>(yup.string, 'phone', function () {
    return this.test('phone', 'El número no es válido.', (value) => {
      const parsed = value ? parsePhoneNumberFromString(value) : null
      return parsed ? parsed.isValid() : false
    })
  })
}
