import LogRocket from 'logrocket'
import { User } from '../ts/types/user.types'

export const canRunLogRocket = () => typeof window !== undefined && process.env.APP_ENV === 'production'

export const initializeLogRocket = (user: User) => {
  if (canRunLogRocket() && user) {
    LogRocket.init('elecnor/simap', {
      network: {
        requestSanitizer: (request) => {
          // if the url contains 'ignore'
          if (request.url.toLowerCase().indexOf('users/sign-in/password') !== -1) {
            // scrub out the body
            request.body = undefined
          }

          return request
        },
      },
    })

    LogRocket.identify(user.id, {
      name: `${user.first_name} ${user.last_name}`, // Full name
      email: user.email, // Email address
      created_at: user.created_at, // Signup date as a Unix timestamp
    })
  }
}
