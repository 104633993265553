import '../styles/globals.css'
import '../styles/rsuite.custom.min.css'
import type { AppProps } from 'next/app'
import { SessionProvider } from 'next-auth/react'
import Head from 'next/head'
import { CustomProvider } from 'rsuite'
import esAR from 'rsuite/locales/es_AR'
import { SWRConfig } from 'swr'
import Script from 'next/script'
import numbro from 'numbro'
import { GOOGLE_ANALYTICS_MEASUREMENT_ID, GOOGLE_MAPS_API_KEY } from '../config'
import { initYup } from '../utils/forms'
import useManagerAuth from '../hooks/useManagerAuth'
import AuthGateway from '../components/shared/AuthGateway'
initYup()

// eslint-disable-next-line @typescript-eslint/no-var-requires
const cl = require('numbro/dist/languages/es-CL.min')
numbro.registerLanguage(cl, true)

interface WorkaroundAppProps extends AppProps {
  err: unknown
}

// eslint-disable-next-line require-jsdoc
function App({ Component, pageProps: { session, ...pageProps }, err }: WorkaroundAppProps) {
  useManagerAuth()

  return (
    <SWRConfig value={{ revalidateOnFocus: false, revalidateOnMount: true }}>
      <Script src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`} />
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_MEASUREMENT_ID}`}
      />

      <Script id="gtag-script" strategy="lazyOnload">
        {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${GOOGLE_ANALYTICS_MEASUREMENT_ID}', {
                      page_path: window.location.pathname,
                    });
                `}
      </Script>

      <CustomProvider locale={esAR}>
        <SessionProvider session={session} refetchInterval={5 * 60}>
          <Head>
            <meta
              name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
            />
          </Head>
          {Component.auth ?? true ? (
            <AuthGateway requiredPermission={Component.requiredPermission}>
              <Component {...pageProps} err={err} />
            </AuthGateway>
          ) : (
            <Component {...pageProps} />
          )}
        </SessionProvider>
      </CustomProvider>
    </SWRConfig>
  )
}

export default App
