import axios from '../utils/services'
import {
  Membership,
  MembershipCreateValues,
  PasswordResetConfirmResponse,
  PasswordResetConfirmValues,
  PasswordResetResponse,
  PasswordResetValues,
  User,
  UserCreateValues,
  UserExists,
  UserRole,
  UserUpdateValues,
} from '../ts/types/user.types'
import { Commune } from '../ts/types/commune.types'

export const findUserByID = (id: string): Promise<User> => {
  return axios.get(`/users/${id}/`)
}

export const findMyUser = (): Promise<User> => {
  return axios.get('/users/me/')
}

export const createUser = (user: UserCreateValues): Promise<User> => {
  return axios.post('/users/', user)
}

export const updateUserByID = (id: string, user: UserUpdateValues): Promise<User> => {
  return axios.patch(`/users/${id}/`, user)
}

export const findMyCommunes = (): Promise<Commune[]> => {
  return axios.get('/users/me/communes/')
}

export const findAllUsersByCommuneSlug = (communeSlug: string): Promise<User[]> => {
  return axios.get(`/users/list/${communeSlug}/`)
}

export const findAllUsers = (): Promise<User[]> => {
  return axios.get('/users/list/')
}

export const createMembership = (membership: MembershipCreateValues): Promise<Membership> => {
  return axios.post('/users/memberships/', membership)
}

export const deleteMembershipByID = (membershipID: string): Promise<void> => {
  return axios.delete(`/users/memberships/${membershipID}/`)
}

export const findAllRoles = (): Promise<UserRole[]> => {
  return axios.get('/users/roles/')
}

export const requestPasswordReset = (values: PasswordResetValues): Promise<PasswordResetResponse> => {
  return axios.post('/users/password/reset/', values)
}

export const confirmPasswordReset = (values: PasswordResetConfirmValues): Promise<PasswordResetConfirmResponse> => {
  return axios.post('/users/password/reset/confirm/', values)
}

export const checkUserExistsByRut = (rut: string): Promise<UserExists> => {
  return axios.get(`/users/exists/${rut}/`)
}
