import { findMyCommunes } from '../services/authenticationService'
import { Commune } from '../ts/types/commune.types'

export const loadUserCommunesSafely = async (): Promise<Commune[]> => {
  try {
    return await findMyCommunes()
  } catch (e) {
    return []
  }
}
